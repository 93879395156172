(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lariAidApp')
        .constant('VERSION', "1.9.26.0")
        .constant('BASE_URL', "https://api.lariaid.mlb.com/")
        .constant('OKTA_SAML_URL', "https://mlb.okta.com/home/mlb_lariaid_1/0oaypjk6m0e0XCk4h0x7/alnypjyl3d9IdwejB0x7")
        .constant('DEBUG_INFO_ENABLED', false)
;
})();
